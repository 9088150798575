import React, { useState, useEffect } from "react"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { FaCheckCircle } from "react-icons/fa"
import { FaCaretLeft } from "react-icons/fa"
import { FaCaretRight } from "react-icons/fa"

const Optin = () => {
  const [optinActive, setOptinActive] = useState(0)

  const [subscribeValues, setSubscribeValues] = useState({
    website: "",
    email: "",
    result: "",
    loading: false,
  })

  const { website, email, result, loading } = subscribeValues

  const handleChange = name => e => {
    setSubscribeValues({
      ...subscribeValues,
      [name]: e.target.value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (website && email) {
      setSubscribeValues({
        ...subscribeValues,
        loading: true,
      })
      const result = await addToMailchimp(email, {
        website: website,
        Tags: "SEO Audit",
        "group[80174][2]": "2",
      })
      if (result.result === "success") {
        setSubscribeValues({
          ...subscribeValues,
          result: result,
          loading: false,
        })
      } else {
        setSubscribeValues({
          ...subscribeValues,
          result: result,
          loading: false,
        })
      }
    } else {
      console.log(`fields is empty`)
    }
  }

  const handleOptin = () => {
    if (optinActive === 1) {
      setOptinActive(0)
    } else {
      setOptinActive(1)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setOptinActive(1)
    }, 240000)
  }, [])

  return (
    <>
      <div className={`${optinActive === 0 ? "optin" : "optin__show"}`}>
        <div className="optin__handle" onPointerDown={handleOptin}>
          {optinActive === 0 && <FaCaretLeft className="optin__closeBtn" />}
          {optinActive === 1 && <FaCaretRight className="optin__closeBtn" />}
          <h6 className="optin__handle--heading">GET FREE WEBSITE SEO AUDIT</h6>
        </div>
        <div className="optin__wrapper ">
          {/* <h2 className="optin__heading">GET YOUR FREE SEO AUDIT</h2> */}
          {!result && (
            <p className="optin__subheading">
              Not sure how well your business site is performing in the web?
              Find out the top 5 website problems you are facing now.
            </p>
          )}

          {result === "" && (
            <form className="optin__form" onSubmit={handleSubmit}>
              <input
                className="optin__input"
                type="text"
                placeholder="Website URL"
                value={website}
                onChange={handleChange("website")}
              />
              {/* <input
                className="optin__input"
                type="text"
                placeholder="Your First Name"
                value={Fname}
                onChange={handleChange("Fname")}
              /> */}
              <input
                className="optin__input"
                type="email"
                placeholder="Your Best Email"
                value={email}
                onChange={handleChange("email")}
              />
              <button
                className={` ${
                  email && website
                    ? "optin__submitBtn btn"
                    : "optin__submitBtn btn-disable"
                }`}
                disabled={loading}
              >
                {loading ? "Submitting..." : "Start Audit"}
                <i className="optin__submitBtn--icon fas fa-arrow-right"></i>
              </button>
            </form>
          )}

          {result.result === "success" && (
            <div className="optin__resultWrapper">
              <div>
                <FaCheckCircle className="optin__icon" />
              </div>
              <p className="optin__result">
                Thank you. Our Team will send you the result for your website.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Optin
