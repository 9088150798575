import React, { useRef, useState, useEffect } from "react"
import { Helmet } from "react-helmet"
// import { ProfessionalService } from "schema-dts"
// import { helmetJsonLdProp } from "react-schemaorg"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import PopupNav from "../components/PopupNav"
import "../styles/style.scss"

import { FaHome } from "react-icons/fa"
import { FaTh } from "react-icons/fa"
import { FaClipboardList } from "react-icons/fa"
import { FaSpinner } from "react-icons/fa"

import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import Optin from "./Optin"

const getLogo = graphql`
  {
    logoImg: file(relativePath: { eq: "logo.png" }) {
      size
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const Layout = ({ children, announcement, countdownData }) => {
  const [timerDays, setTimerDays] = useState("00")
  const [timerHours, setTimerHours] = useState("00")
  const [timerMinutes, setTimerMinutes] = useState("00")
  const [timerSeconds, setTimerSeconds] = useState("00")

  let interval = useRef()

  const startTimer = () => {
    const countdownDate = new Date(countdownData).getTime()

    interval = setInterval(() => {
      const now = new Date().getTime()
      const distance = countdownDate - now

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      if (distance < 0) {
        // Stop timer
        clearInterval(interval.current)
      } else {
        // Update timer
        setTimerDays(days)
        setTimerHours(hours)
        setTimerMinutes(minutes)
        setTimerSeconds(seconds)
      }
    }, 1000)
  }

  const [quoteNext, setQuoteNext] = useState(0)

  const [formData, setFormData] = useState({
    type: "",
    budget: "",
    startProject: "",
  })

  const [contactForm, setContactForm] = useState({
    contactName: "",
    contactEmail: "",
    contactNumber: "",
    contactCompany: "",
    contactPosition: "",
    loading: false,
    error: false,
    contactFormMessage: "",
  })

  const {
    contactName,
    contactEmail,
    contactNumber,
    contactCompany,
    contactPosition,
    loading,
  } = contactForm

  const [goalChecked, setGoalChecked] = useState([])

  const handleChangeContact = name => e => {
    setContactForm({
      ...contactForm,
      error: false,
      [name]: e.target.value,
    })
  }

  const handleQuoteNext = num => {
    if (goalChecked.length > 0) {
      setQuoteNext(num + 1)
    }
  }
  const handleQuotePrev = num => {
    setQuoteNext(num - 1)
  }

  const handleFormType = (num, text) => {
    setFormData({
      ...formData,
      type: text,
    })
    setQuoteNext(num + 1)
  }
  const handleFormBudget = (num, text) => {
    setFormData({
      ...formData,
      budget: text,
    })
    setQuoteNext(num + 1)
  }
  const handleFormStart = (num, text) => {
    setFormData({
      ...formData,
      startProject: text,
    })
    setQuoteNext(num + 1)
  }

  const handleCheckedGoals = (id, value) => {
    const clickedGoal = goalChecked.indexOf(id)
    const selected = [...goalChecked]
    if (clickedGoal === -1) {
      selected.push(id)
    } else {
      selected.splice(clickedGoal, 1)
    }
    setGoalChecked(selected)
  }

  const findOutGoals = id => {
    // console.log(id)
    const result = goalChecked.indexOf(id)
    if (result !== -1) {
      return true
    } else {
      return false
    }
  }

  // const [status, setStatus] = useState(false)

  const handleSubmit = ev => {
    ev.preventDefault()

    if (
      contactName === "" ||
      contactEmail === "" ||
      contactNumber === "" ||
      contactCompany === "" ||
      contactPosition === "" ||
      loading
    ) {
      setContactForm({
        ...contactForm,
        contactFormMessage: "Please fillup your contact details",
      })
      // console.log(`failed`)
    } else {
      // console.log(`send`)
      setContactForm({
        ...contactForm,
        loading: true,
        error: false,
        contactFormMessage: "",
      })

      const formspreeAction = "https://formspree.io/f/xdopkpkz"
      const form = ev.target
      const data = new FormData(form)
      const xhr = new XMLHttpRequest()
      xhr.open(form.method, formspreeAction)
      xhr.setRequestHeader("Accept", "application/json")
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return
        if (xhr.status === 200) {
          form.reset()
          setContactForm({
            ...contactForm,
            loading: false,
          })
          setQuoteNext(5)
        } else {
          console.log(`Message Failed to Send`)
        }
      }
      xhr.send(data)
    }
  }

  const [toggleHome, setToggleHome] = useState(false)
  const [quoteShow, setQuoteShow] = useState(false)
  const [popupNavShow, setPopupNavShow] = useState(false)

  const handleQuoteShow = () => {
    setQuoteShow(true)
    setToggleHome(true)
    setPopupNavShow(false)
  }
  const handleHomeShow = () => {
    setQuoteShow(false)
    setToggleHome(false)

    if (!toggleHome) {
      // console.log(`show menu`)
      setPopupNavShow(!popupNavShow)
    }
    // else {
    // console.log(`show home`)
    // }
  }

  const handleGetQuote = e => {
    e.preventDefault()

    // Lets track that custom click
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Get Quote Button",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "Get Quote Campaigne",
    })
  }

  // const [optinShow, setOptinShow] = useState(0)

  // const handleOptin = () => {
  //   console.log(`object`)
  //   if (optinShow === 1) {
  //     setOptinShow(0)
  //   } else {
  //     setOptinShow(1)
  //   }
  // }

  useEffect(() => {
    startTimer()
    const intervalCurrent = interval.current

    return () => {
      clearInterval(intervalCurrent)
      // if (window.innerWidth > 700) {
      //   setPopupNavShow(false)
      // }
    }
  }, [startTimer, timerDays, timerHours, timerMinutes, timerSeconds])

  const {
    logoImg: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(getLogo)

  return (
    <>
      <section className="announcement container-fluid">
        <p className="announcement__text text-center">{announcement}</p>
      </section>

      {popupNavShow && <PopupNav />}

      <section className="mobileNav">
        <div className="mobileNav__left" onClick={handleQuoteShow}>
          <FaClipboardList className="mobileNav__left--icon" />
          <p onClick={handleGetQuote} className="mobileNav__menu">
            Get Quote
          </p>
        </div>
        <div className="mobileNav__right" onClick={handleHomeShow}>
          <p className="mobileNav__menu">{toggleHome ? `Main` : `Menu`}</p>

          {toggleHome ? (
            <FaHome className="mobileNav__right--icon" />
          ) : (
            <FaTh className="mobileNav__right--icon" />
          )}
        </div>
      </section>
      <main className="main">
        <div
          className={`main__left container-sidebar ${
            quoteShow ? "show-item" : ""
          }`}
        >
          <div className="sidebar">
            <div>
              <div className="sidebar__logo">
                <Link to="/">
                  <div className="sidebar__logoWrapper">
                    {/* <img
                      src={require("../images/logo.png")}
                      alt="greatweb logo"
                      className="sidebar__logo--img"
                    /> */}
                    <Image
                      className="sidebar__logo--img"
                      fluid={fluid}
                      alt="Great Web Philippines"
                    />
                  </div>
                </Link>
              </div>
              <div className="sidebar__headingWrapper">
                <h2 className="sidebar__heading">
                  REQUEST A QUOTATION TODAY TO GET
                  <span className="sidebar__heading--highlight"> FREE </span>
                  <span className="sidebar__heading--highlight-2"> 2yrs </span>
                  WEB HOSTING
                </h2>
              </div>

              <div
                className={` ${
                  quoteNext === 0 ? "sidebar__form " : "sidebar__form hide-item"
                }`}
              >
                <p className="sidebar__form--question">
                  What kind of website do you need?
                </p>

                <ul className="sidebar__list list-unstyled">
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormType(0, "informationalWebsite")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.type === "informationalWebsite"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">
                      Informational Website
                    </p>
                  </li>
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormType(0, "ecommerceWebsite")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.type === "ecommerceWebsite"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">E-commerce Website</p>
                  </li>
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormType(0, "largeEnterprise")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.type === "largeEnterprise"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">
                      Large Enterprise Website
                    </p>
                  </li>
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormType(0, "customWebsite")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.type === "customWebsite"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">Other Custom Website</p>
                  </li>
                </ul>
                {/* <button className="sidebar__form--btn btn btn-primary btn-block">
                  Next
                  <i className="sidebar__form--icon fas fa-arrow-right"></i>
                </button> */}
              </div>

              <div
                className={` ${
                  quoteNext === 1 ? "sidebar__form " : "sidebar__form hide-item"
                }`}
              >
                <p className="sidebar__form--question">
                  What's your budget for this website?
                </p>

                <ul className="sidebar__list list-unstyled">
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormBudget(1, "50k100k")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.budget === "50k100k"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">₱50,000-₱100,000</p>
                  </li>
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormBudget(1, "100k150k")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.budget === "100k150k"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">₱100,000-₱150,000</p>
                  </li>
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormBudget(1, "150k300k")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.budget === "150k300k"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">₱150,000-₱300,000</p>
                  </li>
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormBudget(1, "300kplus")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.budget === "300kplus"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">₱300,000 ++</p>
                  </li>
                </ul>
                <button
                  className="sidebar__form--btn btn btn-primary btn-block"
                  onClick={() => handleQuotePrev(1)}
                >
                  Back
                  <i className="sidebar__form--icon fas fa-arrow-right"></i>
                </button>
              </div>

              <div
                className={` ${
                  quoteNext === 2 ? "sidebar__form " : "sidebar__form hide-item"
                }`}
              >
                <p className="sidebar__form--question">
                  When do you want to get started?
                </p>

                <ul className="sidebar__list list-unstyled">
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormStart(2, "ThisWeek")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.startProject === "ThisWeek"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">This Week</p>
                  </li>
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormStart(2, "ThisMonth")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.startProject === "ThisMonth"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">This Month</p>
                  </li>
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormStart(2, "NextMonth")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.startProject === "NextMonth"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">Next Month</p>
                  </li>
                  <li
                    className="sidebar__item"
                    onClick={() => handleFormStart(2, "2Monthsonwards")}
                  >
                    <span className="sidebar__item--radioBtn">
                      <span
                        className={
                          formData.startProject === "2Monthsonwards"
                            ? "sidebar__item--circle--active"
                            : "sidebar__item--circle"
                        }
                      ></span>
                    </span>

                    <p className="sidebar__item--label">2 Months onwards</p>
                  </li>
                </ul>
                <button
                  className="sidebar__form--btn btn btn-primary btn-block"
                  onClick={() => handleQuotePrev(2)}
                >
                  Back
                  <i className="sidebar__form--icon fas fa-arrow-right"></i>
                </button>
              </div>

              <div
                className={` ${
                  quoteNext === 3 ? "sidebar__form " : "sidebar__form hide-item"
                }`}
              >
                <p className="sidebar__form--question">
                  Choose the Goals you want to achieve that you want us to help
                  you.{" "}
                  <span className="text-danger d-block">
                    (Just Check as many as you want)
                  </span>
                </p>

                <div className="mt-4">
                  <label className="checkbox-container mt-2">
                    Brand Awareness and Get More New Clients
                    <input
                      type="checkbox"
                      checked={findOutGoals("brandAwareness")}
                      value="Brand Awareness and Get More New Clients"
                      onChange={() => handleCheckedGoals("brandAwareness")}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="checkbox-container mt-2">
                    Increase Appointments, Bookings and Phone Calls
                    <input
                      type="checkbox"
                      checked={findOutGoals("increaseAppointments")}
                      value="Increase Appointments, Bookings and Phone Calls"
                      onChange={() =>
                        handleCheckedGoals("increaseAppointments")
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="checkbox-container mt-2">
                    Bring More Bigger Better Projects
                    <input
                      type="checkbox"
                      checked={findOutGoals("bringMoreProjects")}
                      value="Bring More Bigger Better Projects"
                      onChange={() => handleCheckedGoals("bringMoreProjects")}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="checkbox-container mt-2">
                    Saving Time and Advertisement Cost
                    <input
                      type="checkbox"
                      checked={findOutGoals("savingTime")}
                      value="Saving Time and Advertisement Cost"
                      onChange={() => handleCheckedGoals("savingTime")}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                <div className="dflex justify-content-between mt-2">
                  <button
                    className="sidebar__form--btn btn btn-primary w-100 mr-1"
                    onClick={() => handleQuotePrev(3)}
                  >
                    Back
                    <i className="sidebar__form--icon fas fa-arrow-right"></i>
                  </button>
                  <button
                    type="button"
                    className={`sidebar__form--btn btn-warning w-100 ${
                      goalChecked.length > 0 ? "btn" : "btn-disable"
                    }`}
                    onClick={() => handleQuoteNext(3)}
                  >
                    Next
                    <i className="sidebar__form--icon fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>

              <div
                className={` ${
                  quoteNext === 4 ? "sidebar__form " : "sidebar__form hide-item"
                }`}
              >
                <p className="sidebar__form--question">
                  What are your Contact Details?
                </p>

                <form
                  className="sidebar__form--details mt-2"
                  onSubmit={handleSubmit}
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="_subject"
                    value={`Get Quote - ${contactName} - Great Web `}
                  />
                  <input
                    type="text"
                    className="input"
                    placeholder="Your Name*"
                    name="name"
                    value={contactName}
                    onChange={handleChangeContact("contactName")}
                  />
                  <input
                    type="text"
                    className="input"
                    placeholder="Primary Email*"
                    name="email"
                    value={contactEmail}
                    onChange={handleChangeContact("contactEmail")}
                  />
                  <input
                    type="text"
                    className="input"
                    placeholder="Mobile Number*"
                    name="contact"
                    value={contactNumber}
                    onChange={handleChangeContact("contactNumber")}
                  />
                  <input
                    type="text"
                    className="input"
                    placeholder="Company*"
                    name="company"
                    value={contactCompany}
                    onChange={handleChangeContact("contactCompany")}
                  />
                  <input
                    type="text"
                    className="input"
                    placeholder="Position*"
                    name="position"
                    value={contactPosition}
                    onChange={handleChangeContact("contactPosition")}
                  />
                  <input
                    type="text"
                    className="input hide-item"
                    name="formData"
                    value={`Type: ${formData.type} Budget: ${formData.budget} Start: ${formData.startProject} Goals: ${goalChecked}`}
                    onChange={() => {}}
                  />
                  <textarea
                    rows="4"
                    cols="50"
                    className="textarea"
                    name="message"
                    placeholder="Write something here if there is anything you need us to work... "
                  ></textarea>

                  <button
                    // disabled={loading}
                    className={`sidebar__form--btn btn-primary btn-block ${
                      !contactName ||
                      !contactEmail ||
                      !contactNumber ||
                      !contactCompany ||
                      !contactPosition ||
                      loading
                        ? "btn-disable"
                        : "btn"
                    }`}
                  >
                    {loading ? "Sending..." : "Get Quote"}
                    {loading && <FaSpinner className="sidebar__form--icon" />}
                  </button>
                </form>
              </div>

              <div
                className={` ${
                  quoteNext === 5 ? "sidebar__form " : "sidebar__form hide-item"
                }`}
              >
                <div className="sidebar__form--success">
                  <div className="sidebar__form--success--imgWrapper">
                    <img
                      className="sidebar__form--success--img"
                      src={require("../images/sendmessage.gif")}
                      alt="Greatweb Success"
                    />
                  </div>
                </div>
                <p className="sidebar__form--success--message">
                  Thank you. Your message has been successfully sent. We will
                  contact you very soon!
                </p>
              </div>

              <div className="sidebar__countdown">
                <p className="sidebar__countdown--heading">
                  Hurry Up! Promo Ends soon
                </p>
                <ul className="sidebar__countdown--list list-unstyled">
                  <li className="sidebar__countdown--item">
                    <div className="sidebar__countdown--item--wrapper">
                      <p className="sidebar__countdown--item--count">
                        {timerDays}
                      </p>
                      <p className="sidebar__countdown--item--label">DAY</p>
                    </div>
                  </li>
                  <li className="sidebar__countdown--item">
                    <div className="sidebar__countdown--item--wrapper">
                      <p className="sidebar__countdown--item--count">
                        {timerHours}
                      </p>
                      <p className="sidebar__countdown--item--label">HRS</p>
                    </div>
                  </li>
                  <li className="sidebar__countdown--item">
                    <div className="sidebar__countdown--item--wrapper">
                      <p className="sidebar__countdown--item--count">
                        {timerMinutes}
                      </p>
                      <p className="sidebar__countdown--item--label">MIN</p>
                    </div>
                  </li>
                  <li className="sidebar__countdown--item">
                    <div className="sidebar__countdown--item--wrapper">
                      <p className="sidebar__countdown--item--count">
                        {timerSeconds}
                      </p>
                      <p className="sidebar__countdown--item--label">SEC</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <p className="sidebar__copyright">
              © 2020,GreatWeb. All Rights Reserved.
            </p>
          </div>
        </div>
        <div className="main__right container-fluid relative">
          {children}
          <div className=" container">
            <Optin />
          </div>
        </div>
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
