import React from "react"
import { Link } from "gatsby"

import { FaHome } from "react-icons/fa"
import { FaUserFriends } from "react-icons/fa"
import { FaStar } from "react-icons/fa"
// import { FaQuoteLeft } from "react-icons/fa"
import { FaMarker } from "react-icons/fa"
import { FaEnvelope } from "react-icons/fa"
import { FaChartLine } from "react-icons/fa"
import { FaPalette } from "react-icons/fa"
import { FaSeedling } from "react-icons/fa"

const PopupNav = () => {
  return (
    <nav className="popupNav">
      <ul className="popupNav__list">
        <Link to="/" className="popupNav__item">
          <FaHome className="popupNav__icon" />
          <p className="popupNav__title">Home</p>
        </Link>
        <Link to="/about" className="popupNav__item">
          <FaUserFriends className="popupNav__icon" />
          <p className="popupNav__title">Why Us</p>
        </Link>
        <Link to="/portfolio" className="popupNav__item">
          <FaSeedling className="popupNav__icon" />
          <p className="popupNav__title">Our Work</p>
        </Link>
        <Link to="/webdesign-philippines" className="popupNav__item">
          <FaPalette className="popupNav__icon" />
          <p className="popupNav__title">Web Design & Development</p>
        </Link>
        <Link to="/seo-philippines" className="popupNav__item">
          <FaChartLine className="popupNav__icon" />
          <p className="popupNav__title">SEO (Google Ranking)</p>
        </Link>
        <Link to="/google-reviews" className="popupNav__item">
          <FaStar className="popupNav__icon" />
          <p className="popupNav__title">SEO (Google Reviews)</p>
        </Link>
        <Link to="/blog" className="popupNav__item">
          <FaMarker className="popupNav__icon" />
          <p className="popupNav__title">Blog</p>
        </Link>
        <Link to="/contact" className="popupNav__item">
          <FaEnvelope className="popupNav__icon" />
          <p className="popupNav__title">Contact</p>
        </Link>
      </ul>
    </nav>
  )
}

export default PopupNav
